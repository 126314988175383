<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item class="title">
        {{
        $t("adnavPage.financingApplicationManagement")
        }}
      </el-breadcrumb-item>
    </el-breadcrumb>
    <el-form class="flex-row" ref="form" :model="form" style="width: 1050px">
      <el-form-item class="flex-row" :label="$t('debtor')">
        <el-input v-model="form.name"></el-input>
      </el-form-item>
      <div style="margin: 0 0 22px 20px">
        <span class="el-form-item__label">{{ $t("financing") }}</span>
        <el-select v-model="form.region" :label="$t('debtor')" :placeholder="$t('selectFinancing')">
          <el-option
            v-for="(item, index) in searchFinac"
            :key="index"
            :label="$t(item.title)"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>

      <el-button class="btn-black" style="margin: 0 0 22px 20px" @click="clickSearch">
        {{
        $t("search")
        }}
      </el-button>
    </el-form>
    <el-table :data="tableData">
      <template slot="empty">
        <div v-loading="tableLoading" v-text="tableEmpty"></div>
      </template>
      <el-table-column prop="id" :label="$t('financingCode')" width="120" align="center"></el-table-column>
      <el-table-column
        prop="user.username"
        :label="$t('debtorTable')"
        min-width="180"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="orderAmount"
        :label="$t('financingAmountAgain')"
        width="180"
        align="center"
      >
        <template
          v-slot="scope"
        >{{ ((Number(scope.row.financingAmount) / $enums.UNIT_MILLION).toFixed(2) || 0) | toThousandFilterTwo }}</template>
      </el-table-column>
      <el-table-column prop="borrowingCycle" :label="$t('term2')" width="120" align="center"></el-table-column>
      <el-table-column prop="interestRate" :label="$t('interestRate')" width="120" align="center">
        <template v-slot="scope">
          {{
          scope.row.interestRate + '%'
          }}
        </template>
      </el-table-column>
      <el-table-column
        prop="shipmentDate"
        :label="$t('investmentStartingDate')"
        width="140"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="promisedPaymentDate"
        :label="$t('deadlineForRepayment')"
        width="140"
        align="center"
      ></el-table-column>
      <el-table-column prop="status" :label="$t('financingTable')" width="98" align="center">
        <template v-slot="scope">
          <span>{{ scope.row.status | financingStat }}</span>
        </template>
      </el-table-column>
      <el-table-column fixed="right" :label="$t('operation')" align="center" width="100">
        <template v-slot="scope">
          <el-button
            size="small"
            class="btn-black"
            @click="toDetail(scope.row)"
          >{{ scope.row.status.toString() === "1" ? $t("approval") : $t("see") }}</el-button>
        </template>
      </el-table-column>
    </el-table>

    <AdPagination
      :currentPage="tablePageCP"
      :pageSize="tablePagePS"
      :pageTotal="tableTotal"
      @handlePage="handlePage"
    ></AdPagination>
  </div>
</template>

<script>
import { apiGetFinanceRequests } from "@/utils/api";
import AdPagination from "@/components/ad-pagination";
export default {
  components: {
    AdPagination
  },
  data() {
    return {
      tableTotal: 0,
      tablePageCP: 1,
      tablePagePS: 10,
      form: {
        name: "",
        region: "0",
      },
      primaryData: [],
      tableData: [],
      searchFinac: this.$enums.FINANCSTATUS,
      dialogVisible: false,
      markItem: {},
      address: "",
      tableLoading: false,
      tableEmpty: this.$t('loading'),
    };
  },
  mounted() {
    this.tableLoading = true;
    this.initData();
  },
  methods: {
    handlePage(val) {
      this.tablePageCP = val.currentPage;
      this.tablePagePS = val.pageSize;
      this.initData();
    },
    // 获取待审核融资列表
    initData() {
      let _this = this;
      let params = { application: this.form.name, status: this.form.region, page: this.tablePageCP, pageSize: this.tablePagePS };
      if (!Number(this.form.region)) delete params.status;
      if (!this.form.name) delete params.application;
      this.$axios.get('/v1/brand-owner/finace-orders', { params: params }).then(result => {
        _this.tableLoading = false;
        if (!result.code) {
          _this.tableData = result.data.rows;
          _this.tableTotal = result.data.count || 0;
        }
      });
      this.tableEmpty = this.$t('tableEmpty');
    },
    clickSearch() {
      this.tableLoading = true;
      this.initData();
    },
    toDetail(row) {
      this.$router.push({ path: "/mark-list/mark-detail", query: { id: row.id, status: row.status } });
    },
  },
  computed: {
    language() {
      return this.$store.getters['auth/language'];
    }
  },
  watch: {
    language() {
      this.tableEmpty = this.$t("tableEmpty");
    }
  },
};
</script>

<style lang="scss" scoped>
.pages {
  // position: absolute;
  // bottom: 10px;
  // right: 50px;
  margin-top: 50px;
  margin-left: 650px;
}
</style>
